@import 'src/theme/variables';

.item {
  color: white;
  background: rgba(8, 31, 62, 0.5);
  border-radius: 0.25rem;
  display: flex;
  width: fit-content;
  margin: 0.125rem 0 0.25rem;
  align-items: center;

  &.follow {
    background: rgba(167, 12, 255, 0.5);
  }

  .icon {
    margin-right: 1rem;
  }

  .username {
    margin-right: 0.5rem;
    opacity: 0.8;
  }

  .content {
    font-weight: 800;
  }
}
.container {
  display: flex;
  align-items: center;
}
.box {
  flex-grow: 1;
  margin-right: $space-8;
}
.delete_button {
  display: none;
  margin-left: $space-8;
  max-width: $space-210;
}

.container:hover .delete_button {
  display: block;
}
.text_btn {
  display: flex;
  justify-content: flex-start;
  gap: $space-6;
  align-items: center;
}

