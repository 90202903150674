@import '~@hse24/shared-components/styles/breakpoints';
@import '~@hse24/shared-components/styles/spacings';
@import '~@hse24/shared-components/styles/colors';
@import '~@hse24/shared-components/styles/fonts';

$width--full-view: 100vw;
$container--padding: 24px;
$default--margin: 16px;

$navbar--width: 54px;
$navbar--height: 4rem;
$footer--height: 3.5rem;
$tabs--min-width: 120px;

$form--width: 310px;

$scaling-factor: 8px;

$brand-orange: #ff3c28;
$brand-orange-hover: #e63e24;
$sale-red: #be0a14;
$white: #ffffff;
$black: #252525;
$iron: #646c84;
$color--grey: #959595;
$color-light-grey: #dde1e7;
$status-winning-green: #3e7f24;
$nightblue: #081f3e;
$nightblue-mid: #475261;
$nightblue-light: #838b95;
$nightblue-lightest: #c2c6cb;
$lightgrey: #dce6eb;
$lightgrey-mid: #e9eff2;
$lightgrey-light: #eaf0f3;
$lightgrey-lightest: #f4f7f9;
$grey: #c6c6c6;
$aqua: #80c8c2;
$aqua-mid: #a0d6d1;
$aqua-light: #bfe3e0;
$aqua-lightest: #e0f1f0;
$curry: #ff8f1f;
$curry-mid: #ffb636;
$curry-light: #ffd469;
$curry-lightest: #fff4e9;
$stone: #e4e4e5;
$stone-mid: #e9e9ea;
$stone-light: #efefef;
$stone-lightest: #f7f7f7;
$rose: #efc8cd;
$rose-mid: #f3d3d7;
$rose-light: #f5dee1;
$rose-lightest: #faeef0;
$olive: #676d49;
$-olive-mid: #8c9176;
$olive-light: #b4b7a5;
$olive-lightest: #d9dad1;
$night-blue-nightblue-4-lightest: #c1c7cf;
$night-blue-nightblue-2-mid: #46576f;
$night-blue-nightblue-3-light: #838f9e;
$dark-shadow-strong: rgba(37, 37, 37, 0.23);
$dark-shadow-light: rgba(37, 37, 37, 0.17);
$white-lilac: #f5f7fb;
$royalblue: #376bbe;
$bright-blue: #eff5fa;
$soft-blue: #b7e2fb;
$sky-blue: #3cb3e7;
$lochmara: #0089c5;
$dark-grey: #8d96a4;
$granit-grey: #616161;
$attention-grey: #595964;
$office-neon-light: #ff257f;
$indiviolet-sunset: #6F00AE;
$success: #31b267;
$red: #D32F2F;
$info: #199eb3;
$warning: #856404;
$disabled: #b2b2b2;
$light-purple: #d3a5ed;
$light-grey: #00000099;
$solitude: #e9ecf2;
$snow-flake: #f0f0f0;
$light-grey-2 :#E5E5E5;
$venetian_red: #BE0A14;

$color--box-shadow: rgba(255, 60, 40, 0.2);
$color--pale-orange: #e63e2433;

$box-shadow-grey: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
  rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

$texas-sunset: #ff9422;
$light-quilt: #ffdfd5;

$font-weight-4: 400;
$font-weight-5: 500;
$font-weight-7: 700;
$font-weight-9: 900;

$letter-spacing-1: 0.01rem;
$letter-spacing-2: 0.02rem;

$bottom-navigation-height: 56px !default;
$button-border-radius: 0;

$primary: $brand-orange;
$secondary: $nightblue;
$grayLight: $lightgrey;
$grayDark: $attention-grey;
$whiteLilac: $white-lilac;
$whiteSmoke: #f5f5f5;
$whiteLilac: $white-lilac;
$background: $white;
$success: $success;
$error: $red;
$lightgreyMid: $lightgrey-mid;
$brightBlue: $bright-blue;
$softBlue: $soft-blue;
$skyBlue: $sky-blue;

$buttonBorderRadius: $button-border-radius;

$underline-offset-link: 0.2rem;

$font-size-12: 0.75rem;

$space-4: 0.25rem;
$space-6: 0.375rem;
$space-10: 0.625rem;
$space-14: 0.875rem;
$space-20: 1.25rem;
$space-28: 1.75rem;
$space-36: 2.275rem;
$space-40: 2.5rem;
$space-42: 2.625rem;
$space-52: 3.25rem;
$space-64: 4rem;
$space-72: 4.5rem;
$space-135: 8.438rem;
$space-148: 8.75rem;
$space-210: 13.125rem;

$font--line-height-50: 150%;
$font--line-height-40: 140%;
$font--line-height-32: 132%;

:export {
  primary: $primary;
  secondary: $secondary;
  grayLight: $grayLight;
  grayDark: $grayDark;
  whiteLilac: $whiteLilac;
  background: $background;
  success: $success;
  error: $error;
  lightgreyMid: $lightgreyMid;
  lightGrey:$lightgrey;
  brightBlue: $brightBlue;
  softBlue: $softBlue;
  skyBlue: $skyBlue;
  lochmara: $lochmara;
  paleOrange: $color--pale-orange;
  white: $color--white;
  nightBlueNightblue4Lightest: $night-blue-nightblue-4-lightest;
  lightgreyLightest: $lightgrey-lightest;

  buttonBorderRadius: $buttonBorderRadius;

  fontSize48: $font-size-48;
  fontSize36: $font-size-36;
  fontSize32: $font-size-32;
  fontSize28: $font-size-28;
  fontSize24: $font-size-24;
  fontSize20: $font-size-20;
  fontSize18: $font-size-18;
  fontSize16: $font-size-16;
  fontSize15: $font-size-15;
  fontSize14: $font-size-14;
  fontSize13: $font-size-13;
  fontSize11: $font-size-11;

  navbarHeight: $navbar--height;

  space12: $space-12;
  space16: $space-16;
  space24: $space-24;

  fontWeight9: $font-weight-9;
  fontWeight7: $font-weight-7;
  fontWeight4: $font-weight-4;

  lineHeight50: $font--line-height-50;
  lineHeight40: $font--line-height-40;
  lineHeight32: $font--line-height-32;

  letterSpacing1: $letter-spacing-1;
}
