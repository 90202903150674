@import 'src/theme/variables';

.creation_overview_container {
  row-gap: $space-24;
  padding-top: $space-40 !important;

  @media (max-width: $viewport--max-width-ms) {
    margin-left: $space-24;
    margin-right: $space-24;
  }

  .links {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: $space-16;
    align-self: stretch;
    flex-wrap: wrap;
    margin-top: $space-16;

    .link {
      display: flex;
      height: 80px;
      max-width: 208px;
      min-width: 120px;
      padding: $space-24 0;
      justify-content: center;
      align-items: center;
      gap: $space-4;
      flex: 1 0 0;
      border-radius: $space-4;
      background-color: $white;
      cursor: pointer;

      &:hover {
        background-color: $lightgrey-mid;
      }
    }

    .disabled_link {
      @extend .link;
      background-color: transparent;
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.creation_text {
  font-weight: $font-weight-4;
}
