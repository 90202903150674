@import 'src/theme/variables';

.wrapper {
  padding: $space-24 0;
  max-width: 600px;
  margin: 0 auto;
  gap: $space-24;

  .page_title {
    display: block;
    font-weight: $font-weight-7;
    font-size: $font-size-24;
    line-height: $space-32;
    margin-bottom: $space-24;
    text-align: center;
  }

  .form_group {
    margin-bottom: $space-16;
  }

  .title {
    margin-bottom: $space-12;
    font-weight: $font-weight-7;
  }

  .chat {
    margin: $space-12 0 $space-12 0;
    font-weight: $font-weight-7;
  }

  .select,
  .input {
    width: 100%;
    padding: $space-8;
    border: 1px solid $night-blue-nightblue-2-mid;
    border-radius: $button-border-radius;
    font-size: $space-14;
  }

  .radio_group {
    display: flex;
    gap: $space-16;
    margin-top: $space-12;

    .radio_buttons {
      display: flex;
      column-gap: $space-24;
      align-items: center;
    }
  }

  .error_message {
    margin-top: $space-8;
  }

  .button_group {
    display: flex;
    justify-content: center;
    margin-top: $space-36;
    width: 100%;

    button {
      width: 100%;
    }

    .buttonRunning {
      width: 100%;
      background-color: $nightblue-light;

      &:hover {
        background-color: $nightblue-lightest;
      }
    }
  }
}
