@import 'src/theme/variables';

.wrapper {
  padding: $space-24 0;

  .page_title {
    display: none;
    margin-bottom: $space-48;

    @media (min-width: $viewport--min-width-md) {
      display: block;
    }
  }

  h1 {
    font-weight: $font-weight-7;
    font-size: $font-size-24;
    margin-bottom: $space-24;
  }

  h2 {
    font-size: $font-size-20;
    line-height: $space-24;
    font-weight: $font-weight-7;
    margin-bottom: $space-8;
  }

  li {
    padding: 0;

    svg {
      color: $black;
    }
  }

  .container {
    .title {
      margin-bottom: $space-12;
      font-weight: $font-weight-7;
    }

    .media_area {
      gap: $space-24;

      .media_slider {
        flex: 0;
      }
    }

    .desktop_actions {
      display: none;
      gap: 21px;

      button {
        flex: 1;
        padding-inline: 2px;
      }
    }

    .music_licence_area {
      display: flex;
    }

    @media (min-width: $viewport--min-width-md) {
      flex-direction: row-reverse;
      flex-wrap: nowrap;

      .media_area {
        flex-wrap: nowrap;
        flex-direction: row-reverse;
      }

      .desktop_actions {
        display: flex;
      }

      .music_licence_area {
        display: none;
      }
    }
  }

  .actions {
    margin-top: $space-16;

    button {
      padding: 0 $space-16 !important;
    }

    @media (min-width: $viewport--min-width-md) {
      display: none;
    }
  }

  .checkbox {
    margin-top: $space-16;
  }

  .date_Selector {
    display: none;
    margin-bottom: $space-8;
  }

  .show_start_time {
    display: flex;
    flex-direction: column;
    gap: $space-8;
  }

  .radio {
    display: flex;
    flex-direction: row;
    gap: $space-16;

    .ration_option {
      margin-block: $space-10;
    }
  }

  .caption {
    border: 2px solid $lightgrey;
    width: 100%;
  }

  .sales_topic_hint {
    font-size: $font-size-12;
    line-height: $font--line-height-body-micro;
    color: $night-blue-nightblue-2-mid;
  }

  .error_message {
    margin-top: $space-8;
  }
}

.image_selection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.image_zone {
  border: 1px solid $nightblue-lightest;
  border-radius: $space-8;
  aspect-ratio: 1/1;
  width: 100%;
  background: $white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .image_box {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    img {
      aspect-ratio: 1/1;
      border-radius: 0.5rem;
    }
  }

  .buttons {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 1rem;
    display: flex;
    gap: 1rem;

    .input {
      display: none;
    }

    .icon_button {
      padding: 1rem;
      border-radius: 2rem;

      &_delete {
        background: $white;
      }
    }

    &.hide {
      display: none;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
