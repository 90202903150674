@import 'src/theme/variables';

.wrapper {
  border-radius: $space-16;
  max-height: auto;
  width: 700px;
  padding-top: 0;
  margin: 0;

  @media (max-width: $viewport--min-width-md) {
    border-radius: $space-16 $space-16 0 0;
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;
    max-height: 400px;
  }
}

.grabber {
  width: $space-36;
  height: 5px;
  background-color: $night-blue-nightblue-4-lightest;
  border-radius: 9999px;
  margin: 0 auto 0 auto;

  @media (max-width: $viewport--min-width-md) {
    margin: $space-6 auto 0 auto;
  }
}

.header {
  padding-top: $space-6;
  display: block;

  @media (max-width: $viewport--min-width-md) {
    padding-top: 0;
  }
}

.componentBody {
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.headerContent {
  justify-content: space-between;
  align-items: center;
  background-color: $white;

  .title {
    margin: $space-6 0 $space-16 $space-16;
  }
}

.closeButton {
  @media (max-width: $viewport--min-width-md) {
    top: $space-10 !important;
  }
}

.divider {
  position: relative;
  width: 100%;
  height: 1px;
  border-top: 1px solid $lightgrey;
}

.content {
  display: flex;
  flex-direction: column;
  gap: $space-8;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  max-height: none;
  overflow-y: visible;
  padding: 0 $space-16 0 $space-16;
  margin: $space-16 0 $space-16 0;
  word-wrap: break-word;
  word-break: break-word;

  &::-webkit-scrollbar {
    display: none;
  }

  .warning_icon {
    align-self: center;
    width: $space-48;
    height: $space-48;
  }

  .text {
    font-weight: $font-weight-7;
    text-align: left;
    vertical-align: top;
    flex: 2;
    white-space: normal;
    overflow-wrap: break-word;
  }

  .copyright_warning_text {
    flex: 1;
    text-align: left;
    font-size: $space-16;
    font-weight: $font-weight-4;
    line-height: $space-24;
    white-space: pre-line;
    color: $night-blue-nightblue-2-mid;

    .afterBreak {
      display: inline-block;
      padding-top: $space-8;
    }

    a {
      color: $brand-orange;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
