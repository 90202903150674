@import 'src/theme/variables';

.header {
  font-weight: 700;
  font-size: $font-size-24;
  margin-bottom: $space-24;
}

.link_back {
  margin-bottom: $space-24;


  button {
    padding-left: 0;
  }
  width: auto;
}

.show_details_container {
  padding: $space-32;
  background: white;
  border-radius: $button-border-radius;
}
.product_popup_btn{
  width: auto
}

.end_show_dialog {
  padding: $space-8 $space-8 $space-8 $space-24 !important;
  border-radius: $space-4 !important;
  width: 560px !important;

  .close_icon {
    display: none;
  }


  .end_show_header {
    font-weight: $font-weight-4;
    font-size: $font-size-20;
    line-height: 28px;
    padding-bottom: $space-16;
  }

  .end_show_body {
    font-size: $font-size-16;
    line-height: $space-24;
    font-weight: $font-weight-4;
    color: $iron;
    padding-bottom: $space-16;
  }

  .button_group {
    display: flex;
    gap: $space-8;
    justify-content: flex-end;

    .cancel_button {
      width: 126px;
    }
    .confirm_button {
      width: 166px;
    }
  }

}


