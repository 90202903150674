@import 'src/theme/variables';

.voucher {
  position: relative;
  display: flex;
  padding: $space-16;
  background-color: $white;
  width: 100%;
  border-radius: $space-8;

  .rightPart {
    width: 60%;
    margin-left: $space-14;

    .description {
      flex: 1;
      text-align: left;
      font-size: $space-14;
      font-weight: $font-weight-4;
      line-height: $space-20;
      color: $night-blue-nightblue-2-mid;

      /* Multi-line truncation */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      line-clamp: 4;

      .link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .leftPart {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: $space-80;
    background: $sale-red;
    border-radius: $space-6;
    min-width: 0;

    .value {
      font-size: $font-size-32;
      font-weight: $font-weight-7;
      padding-top: 0;
      padding-bottom: 0;
    }

    .valueSmall {
      width: 90%;
      max-width: 100%;
      font-size: $space-10;
      font-weight: $font-weight-7;
      padding: $space-6 0 0 0;

      p {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.voucher::before,
.voucher::after {
  content: '';
  position: absolute;
  top: 50%;
  width: $space-16;
  height: $space-16;
  background: #f5f5f5;
  border-radius: 50%;
  transform: translateY(-50%);
}

.voucher::before {
  left: -$space-8;
}

.voucher::after {
  right: -$space-8;
}

.contentSlider {
  width: 100%;
  padding-top: $space-16;
  position: relative;
  max-width: 100%;
  overflow-x: clip;
}

.emblaViewport {
  width: 100%;
}

.slidesWrapper {
  display: flex;
  gap: $space-16;
  will-change: transform;
}

.slide {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  overflow: hidden;
  max-width: 400px;
  user-select: none;

  @media (max-width: $viewport--max-width-ms) {
    min-width: 20.5rem;
    max-width: 20.5rem;

    &:first-child {
      padding-left: $space-24;
    }

    &:not(:only-child):last-child {
      padding-right: $space-24;
    }
  }

  @media (min-width: #{($viewport--max-width-ms + 1)}) {
    min-width: 20.5rem;
  }
}
