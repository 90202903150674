@import 'src/theme/variables';

.wrapper {
  padding-top: $space-12;
  height: 95px;

  @media (min-width: $viewport--min-width-md) {
    padding-top: $space-20;
  }
}

.content {
  cursor: pointer;
}

.title {
  margin-bottom: $space-12;
  font-weight: $font-weight-7;
}

.warning_icon {
  margin-top: 2px;
}

.instructions_popup {
  color: $primary;
  cursor: pointer;
  text-underline-offset: $space-4;
  text-decoration: underline;
}
