@import 'src/theme/variables';

.upload_area {
  border: 2px dashed $lightgrey;
  background-color: $white;
  display: table;
  position: relative;
  width: 100%;
  height: 428.75px;

  @media (min-width: $viewport--min-width-md) {
    height: 666px;
  }

  .center_container {
    display: flex;
    justify-content: center;
    justify-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    flex-wrap: wrap;
    width: 100%;
    gap: $space-8;

    .upload_statuses {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: $space-8;
    }

    .cancel_upload {
      margin-top: 8px;

      button {
        padding: $space-8 $space-16;
        min-width: 200px;
      }
    }

    .pictures_icon {
      width: 100%;
      color: $black;

      svg {
        height: 80px;
        width: 100%;
      }
    }
    .pictures_icon_error {
      color: $sale-red;
    }

    .upload_input {
      display: none;
    }

    .description_error {
      color: $sale-red;
    }

    .description {
      font-weight: $font-weight-4;
      font-size: $font-size-20;
      line-height: 28px;
      text-align: center;
      margin-bottom: $space-8;
      width: 100%;
    }

    .upload_actions {
      .upload_input {
        display: none;
      }

      .upload_button {
        font-weight: $font-weight-7;
      }
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin-bottom: $space-8;
    font-weight: $font-weight-4;
    font-size: $font-size-14;
    line-height: 21px;
    color: $iron;

    .format_message {
      text-indent: $space-4;
      font-family: Lato;
    }

    .upload_in_progress_description {
      font-size: $font-size-12;
      line-height: $space-18;
    }

    .instructions_popup {
      color: $primary;
      cursor: pointer;
      text-underline-offset: $space-4;
    }

    @media (min-width: $viewport--min-width-md) {
      font-size: $font-size-16;
      line-height: $space-24;
    }
  }
}

.error {
  border: 2px solid $sale-red;
  border-radius: $space-4;
}
