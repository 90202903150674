@import 'src/theme/variables';

.dialog_header {
  font-size: $font-size-20;
  font-weight: $font-weight-4;
  line-height: $space-28;
}
.content_msg {
  font-size: $font-size-16;
  font-weight: $font-weight-4;
  line-height: $space-24;
  color: $iron;
}
.buttons {
  width: auto;
}