@import 'src/theme/variables';

h1 {
  font-weight: $font-weight-7;
  font-size: $font-size-24;
  margin-bottom: $space-24;
}

h2 {
  font-size: $font-size-20;
  line-height: $space-24;
  font-weight: $font-weight-7;
  margin-bottom: $space-8;
}

li {
  padding: 0;

  svg {
    color: $black;
  }
}

.title {
  margin-bottom: $space-12;
  font-weight: $font-weight-7;
}

.media_area {
  gap: $space-24;

  .media_slider {
    flex: 0;
  }

  @media (min-width: $viewport--min-width-md) {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
  }
}

.checkbox {
  margin-top: $space-16;
}

.date_Selector {
  display: none;
  margin-bottom: $space-8;
}

.show_start_time {
  display: flex;
  flex-direction: column;
  gap: $space-8;
}

.radio {
  display: flex;
  flex-direction: row;
  gap: $space-16;

  .ration_option {
    margin-block: $space-10;
  }
}

.caption {
  border: 2px solid $lightgrey;
  width: 100%;
}

.sales_topic_hint {
  font-size: $font-size-12;
  line-height: $font--line-height-body-micro;
  color: $night-blue-nightblue-2-mid;
}

.error_message {
  margin-top: $space-8;
}

.music_licence_popup {
  display: none;

  @media (min-width: $viewport--min-width-md) {
    display: flex;
  }
}
